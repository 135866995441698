import {
  SET_INPUT_SOLICITUDES,
  BACK_STEEP_SOLICITUDES,
  NEXT_STEEP_SOLICITUDES,
  RESET_STEEP_SOLICITUDES,
} from "../types";
import ICampoSolicitud from "../../interfaces/ICampoSolicitud";

export const setCamposSolicitud = (payload: ICampoSolicitud) => ({
  type: SET_INPUT_SOLICITUDES,
  payload,
});
export const nextSteep = (solicitudPara: string = "") => ({
  type: NEXT_STEEP_SOLICITUDES,
  payload: solicitudPara,
});
export const backSteep = (solicitudPara: string = "") => ({
  type: BACK_STEEP_SOLICITUDES,
  payload: solicitudPara,
});
export const resetSteep = () => ({ type: RESET_STEEP_SOLICITUDES });
