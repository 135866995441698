import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SparkMD5 from "spark-md5";
import { useNavigate, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleLeft } from "@fortawesome/free-solid-svg-icons";
import config from "../../config";

import { login } from "../../store/actions/authActions";
import { RootState } from "../../store/reducers";
import formToObj from "../../utils/formToObj";
import Api from "../../utils/api";
import IAuth from "../../interfaces/IAuth";
import Button from "../../components/Button";

import { useTranslation } from "react-i18next";
import { namespaces } from "../../i18n/i18n.constants";

export default function Login() {
  const [messageApi, setMessageApi] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [loginPhoto, setLoginPhoto] = useState();

  const { t } = useTranslation(namespaces.pages.login);
  const { t: tg } = useTranslation(namespaces.common);

  const form = useRef<HTMLFormElement>(null);
  // navigate
  const navigate = useNavigate();

  // state
  const state = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoginPhoto(
      require(`../../assets/images/${process.env.REACT_APP_PLATFORM?.toLocaleLowerCase()}/login.jpeg`)
    );
  }, []);

  function handleLogin() {
    if (!form.current) {
      return;
    }

    const body = formToObj(form.current);

    setMessageApi("");
    setIsFetching(true);

    Api("login")
      .post({ ...body, clave: SparkMD5.hash(String(body.clave).toUpperCase()) })
      .then((res) => {
        if (res.mensaje.tipo === "E") {
          setMessageApi(res.mensaje.descripcion);
          return;
        }

        const auth: IAuth = {
          nombre: res.data.nombre,
          apellido: res.data.apellido,
          empresa: res.data.empresa,
          idUsuario: res.data.idUusario,
          jwt: res.jwt,
          cambiarClave: res.cambiarClave === "S",
          email: body.usuario.toString(),
          sesionTime: Date.now() + config.SESION_TIME,
        };
        dispatch(login(auth));

        if (res.cambiarClave === "S") {
          navigate("/cambiar-clave");
          return;
        }
      })
      .catch((err) => {
        setMessageApi(err.message);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }

  useEffect(() => {
    document.querySelector("body")?.classList.add("bg-login");
    if (!state.auth.jwt) {
      return;
    }
    document.querySelector("body")?.classList.remove("bg-login");
    if (state.auth.cambiarClave) {
      navigate("/usuario/cambiar-clave");
      return;
    }
    navigate("/usuario/tus-datos");
  }, [state, navigate]);

  return (
    <div className="bg-login pb-sm-5 pb-1 mt-sm-3  mt-lg-0 pt-1 px-3">
      <a
        href={process.env.REACT_APP_HOMEPAGE}
        className="text-white fs-3 text-decoration-none"
      >
        <FontAwesomeIcon icon={faChevronCircleLeft} className="me-2" />
        {t("buttons.back")}
      </a>
      <h1 className="h1-login-title d-flex justify-content-center mx-auto mb-sm-5 pb-1">
        {process.env.REACT_APP_PLATFORM} - {tg("session.sucursalVirtual")}
      </h1>
      <div
        className="container my-auto bg-light rounded shadow-lg pl-5"
        style={{ maxWidth: 1140 }}
      >
        <div className="row">
          <div
            className="col-md-6 col-12 bg-login-image"
            style={{ backgroundImage: `url(${loginPhoto})` }}
          ></div>
          <div className="col-md-6 col-12 bg-white rounded-2 p-5 container-login-form">
            <h1 className="color-platform text-center mb-sm-5 mb-2">
              {t("titles.login")}
            </h1>
            <form ref={form}>
              <div>
                <span className="fw-bold">{t("titles.email")}</span>
                <input
                  className="form-control"
                  type="email"
                  name="usuario"
                  placeholder="ejemplo@correo.com"
                  required
                />
              </div>
              <div className="mt-4">
                <span className="fw-bold">{tg("labels.password")}</span>
                <input
                  className="form-control"
                  type="password"
                  name="clave"
                  onKeyDown={(e) => (e.key === "Enter" ? handleLogin() : null)}
                  placeholder="*******"
                  required
                />
              </div>
              <div className="text-center mt-4">
                <Button
                  title={String(t("buttons.login"))}
                  onLoad={isFetching}
                  onPress={() => handleLogin()}
                />
                <p className="ml-0 mt-3">
                  <Link to={"/estadias"} className="a-link ps-1">
                    {t("validateStay")}
                  </Link>
                </p>
              </div>
              <p className="text-danger text-center mt-1">{messageApi}</p>
            </form>
            <div className="d-flex justify-content-center" id="opts-login">
              <ul className="login-more p-t-20">
                <li className="m-b-8">
                  <span className="text-muted">
                    {tg("session.forgotYourPassword")}
                  </span>
                  <Link className="a-link ps-1" to="/recuperar">
                    {tg("session.recoveryPassword")}
                  </Link>
                </li>
                <li>
                  <span className="text-muted">
                    {tg("session.dontHaveAccount")}
                  </span>
                  <Link className="a-link ps-1" to="/registro">
                    {tg("session.register")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
