import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/reducers";
import { backSteep } from "../../store/actions/solicitudActions";
import Api from "../../utils/api";
import OBJtoXML from "../../utils/objToXML";
import IResponseSolicitud from "../../interfaces/IResponseSolicitud";
import Button from "../Button";
import ModalTyC from "./ModalTyC";
import Modal from "../Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { useNavigate } from "react-router-dom";

export default function DatosDelPasajero() {
  const [porQuqe, setPorQue] = useState("");

  const [showModalTYC, setShowModalTYC] = useState(false);
  const [apiMessage, setApiMessage] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [textSuccess, setTextSuccess] = useState("");

  const objSolicitud = useSelector((state: RootState) => state.ObjSolicitud);
  const campos = useSelector((state: RootState) => state.campoSolicitud);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  function handleClickBack() {
    dispatch(backSteep(objSolicitud.SOLICITUD.VIAJE_PARA));
  }

  function handleClickModalTYC() {
    setShowModalTYC(false);
    setApiMessage("");
    const pcRequest: IResponseSolicitud = {
      SOLICITUD: {
        ...objSolicitud.SOLICITUD,
        PORQUE: porQuqe,
      },
    };
    setIsFetching(true);
    Api("consumir")
      .post({
        servicio: "altaSolicitud",
        param: {
          pn_pagina: 6,
          pc_request: OBJtoXML(pcRequest),
        },
      })
      .then((res) => {
        if (res.mensaje.tipo === "M") {
          setTextSuccess(res.mensaje.descripcion);
          setShowModalSuccess(true);
          return;
        }
        setApiMessage(res.mensaje.descripcion);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }

  function handleClickValidar() {
    if (!porQuqe) {
      return;
    }
    setShowModalTYC(true);
  }

  const handleSuccess = () => {
    navigate("/solicitudes/tus-solicitudes");
  };

  return (
    <div className="container mb-5">
      <div className="row">
        <div className="col-12 my-2">
          <span>¿Por qué nos elegiste?</span>
          <select
            onChange={(e) => setPorQue(e.target.value)}
            className="form-select"
          >
            {campos.pqnoselegiste.map((campo) => (
              <option key={campo.codigo} value={campo.codigo}>
                {campo.dato}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="text-center mt-4">
        <Button secondary title="Volver" onPress={handleClickBack} />
        <Button
          title="Continuar"
          onLoad={isFetching}
          onPress={handleClickValidar}
        />
      </div>
      {apiMessage ? (
        <div dangerouslySetInnerHTML={{ __html: apiMessage }}></div>
      ) : (
        <></>
      )}
      <ModalTyC
        show={showModalTYC}
        body={campos.tyc[0].tyc}
        onAcept={() => handleClickModalTYC()}
        onClose={() => setShowModalTYC(false)}
      />
      <Modal
        title=""
        btnAceptar="Aceptar"
        onClose={() => {}}
        onAceptar={handleSuccess}
        hideClose
        show={showModalSuccess}
      >
        <div className="text-center">
          <FontAwesomeIcon color="#20c997" icon={faCheckCircle} fontSize={80} />
          <h5 className="d-block mt-3">{textSuccess}</h5>
        </div>
      </Modal>
    </div>
  );
}
