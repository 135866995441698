import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Login from "../pages/session/LogIn";
import Recuperar from "../pages/session/Recuperar";
import Estadias from "../pages/estadia/Estadias";

import TusDatos from "../pages/user/TusDatos";
import CambiarClave from "../pages/user/CambiarClave";
import Registro from "../pages/session/Registro";
import CambiarClaveRecuperar from "../pages/session/CambiarClave";

import DatosDelPasajero from "../pages/pasajero/DatosDelPasajero";
import GrupoDeViaje from "../pages/pasajero/GrupoDeViaje";
import CuotasYPago from "../pages/pasajero/CuotasYPago";
import LugarYHorarioDeSalida from "../pages/pasajero/LugarYHorarioDeSalida";
import CuotaCero from "../pages/pasajero/CuotaCero";
import FichaMedica from "../pages/pasajero/FichaMedica";
import CargarEditarFichaMedica from "../pages/pasajero/CargarEditarFichaMedica";
import ServiciosIndividuales from "../pages/pasajero/ServiciosIndividuales";
import ActualizarCuota from "../pages/pasajero/ActualizarCuota";

import TusSolicitudes from "../pages/solicitudes/TusSolicitudes";
import SolicitudNueva from "../pages/solicitudes/SolicitudNueva";

import VerTurnos from "../pages/turnos/VerTurnos";
import AgendarTurno from "../pages/turnos/AgendarTurno";

import PanelLogeado from "../layauts/PanelLogeado";
import PanelUsuario from "../layauts/PanelUsuario";
import PanelPasajero from "../layauts/PanelPasajero";
import PanelSolicitud from "../layauts/PanelSolicitud";
import Public from "../layauts/Public";

import { RootState } from "../store/reducers";
import Concursos from "../pages/pasajero/Concursos";
import NewConcurso from "../pages/pasajero/NewConcurso";
import ComprarServicio from "../pages/app/ComprarServicio";
import SuccessMercadoPago from "../pages/app/SuccessMercadoPago";
import PendingMercadoPago from "../pages/app/PendingMercadoPago";
import FailureMercadoPago from "../pages/app/FailureMercadoPago";
import { AutoLogin } from "../pages/session/AutoLogin";

const TestOpenApp = () => {
  useEffect(() => {
    try {
      window.location.href = "com.maxdreamapp://url_mp_success";
      // (window as any).ReactNativeWebView.postMessage("com.maxdreamapp://successpurchase");
    } catch (error) {}
  }, []);
  return <div>redirecting...</div>;
};

export default function RoutesFunction() {
  const [isLoggedin, setIsLoggedin] = useState(false);
  const auth = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    setIsLoggedin(!!auth.jwt);
  }, [auth.jwt]);

  return (
    <Routes>
      <Route element={<Public />}>
        <Route path="/test-open-app" element={<TestOpenApp />} />
        <Route path="/" element={<Login />} />
        <Route path="/iniciar-sesion" element={<Login />} />
        <Route path="/recuperar" element={<Recuperar />} />
        <Route path="/registro" element={<Registro />} />
        <Route path="/auto-login/:jwt" element={<AutoLogin />} />
        <Route path="/cambiar-clave" element={<CambiarClaveRecuperar />} />
        <Route path="/estadias" element={<Estadias />} />
        <Route path="/url_mp_success" element={<SuccessMercadoPago />} />
        <Route path="/url_mp_failure" element={<PendingMercadoPago />} />
        <Route path="/url_mp_pending" element={<FailureMercadoPago />} />
        <Route path="/app">
          <Route path="comprar-servicio" element={<ComprarServicio />} />
        </Route>
      </Route>
      {isLoggedin ? (
        <Route path="/" element={<PanelLogeado />}>
          <Route path="usuario" element={<PanelUsuario />}>
            <Route path="tus-datos" element={<TusDatos />} />
            <Route path="cambiar-clave" element={<CambiarClave />} />
          </Route>
          <Route path="turno">
            <Route path="ver-turnos" element={<VerTurnos />} />
            <Route path="agendar" element={<AgendarTurno />} />
          </Route>
          <Route path="solicitudes" element={<PanelSolicitud />}>
            <Route path="tus-solicitudes" element={<TusSolicitudes />} />
            <Route path="nueva" element={<SolicitudNueva />} />
          </Route>
          <Route path="/pasajero/:id/:nombre" element={<PanelPasajero />}>
            <Route path="datos-del-pasajero" element={<DatosDelPasajero />} />
            <Route path="grupo-de-viaje" element={<GrupoDeViaje />} />
            <Route path="cuotas" element={<CuotasYPago />} />
            <Route path="cuotas/actualizar" element={<ActualizarCuota />} />
            <Route path="cuota-cero" element={<CuotaCero />} />
            <Route path="ficha-medica" element={<FichaMedica />} />
            <Route
              path="lugar-horario-salida"
              element={<LugarYHorarioDeSalida />}
            />
            <Route
              path="servicios-individuales"
              element={<ServiciosIndividuales />}
            />
            <Route
              path="ficha-medica/cargar-editar"
              element={<CargarEditarFichaMedica />}
            />
            <Route path="concursos" element={<Concursos />} />
            <Route path="concursos/:idconcurso" element={<NewConcurso />} />
          </Route>
          <Route path="*" element={<h1>page not found</h1>} />
        </Route>
      ) : (
        <Route path="*" element={<RedirectToLogin />} />
      )}
    </Routes>
  );
}

function RedirectToLogin() {
  const navigate = useNavigate();
  useEffect(() => {
    const ls: string = localStorage.getItem("auth") || "";
    if (!ls) {
      navigate("/iniciar-sesion");
      return;
    }

    const lsjson = JSON.parse(ls);
    if (!lsjson.jwt) {
      navigate("/iniciar-sesion");
      return;
    }
  }, [navigate]);
  return <></>;
}
