import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ImageLogo from "../../assets/images/logo.png";

import { login } from "../../store/actions/authActions";
import { useParams } from "react-router-dom";
import Api from "../../utils/api";
import IAuth from "../../interfaces/IAuth";
import config from "../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export const AutoLogin = () => {
  let { jwt = "" } = useParams();

  // navigate
  const navigate = useNavigate();

  // state
  // const state = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  const [messageApi, setMessageApi] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (isFetching || messageApi) return;
    setIsFetching(true);
    Api("consumir", jwt)
      .post({
        servicio: "loginInfo",
      })
      .then((res) => {
        if (res.mensaje.tipo === "E") {
          setMessageApi(res.mensaje.descripcion);
          return;
        }

        const auth: IAuth = {
          nombre: res.PD_NOMBRE,
          apellido: res.PD_APELLIDO,
          empresa: res.PD_EMPRESA,
          idUsuario: res.IDUSUARIO,
          jwt,
          cambiarClave: res.PM_CAMBIAR_CLAVE === "S",
          email: res.PD_EMAIL,
          sesionTime: Date.now() + config.SESION_TIME,
        };
        dispatch(login(auth));

        if (res.cambiarClave === "S") {
          navigate("/cambiar-clave");
          return;
        }
        navigate("/usuario/tus-datos");
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, [jwt, dispatch, navigate, isFetching, messageApi]);
  return (
    <div
      className="bg-login"
      style={{
        minWidth: "100vw",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
      }}
    >
      <div
        className="logo-layout mx-auto"
        style={{
          backgroundImage: `url('${ImageLogo}')`,
          minHeight: "10em",
          minWidth: "100vw",
          backgroundPosition: "center",
          backgroundSize: "contain",
        }}
      ></div>
      <h1>
        {isFetching && (
          <FontAwesomeIcon icon={faSpinner} className="spinner text-white" />
        )}
        {messageApi && <h1 className="text-white p-1 pt-4">{messageApi}</h1>}
      </h1>
    </div>
  );
};
