import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/reducers";
import { setSolicitud } from "../../store/actions/objSolicitudActions";
import { nextSteep, backSteep } from "../../store/actions/solicitudActions";
import Api from "../../utils/api";
import OBJtoXML from "../../utils/objToXML";
import IResponseSolicitud from "../../interfaces/IResponseSolicitud";
import Button from "../Button";
import formToObj from "../../utils/formToObj";

export default function DatosPadres() {
  const form1 = useRef(null);
  const form2 = useRef(null);

  const [apiMessage, setApiMessage] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  const objSolicitud = useSelector((state: RootState) => state.ObjSolicitud);
  const campos = useSelector((state: RootState) => state.campoSolicitud);

  const dispatch = useDispatch();

  function handleClickBack() {
    dispatch(backSteep(objSolicitud.SOLICITUD.VIAJE_PARA));
  }

  function handleClickValidar() {
    setApiMessage("");

    if (!form1.current || !form2.current) {
      return;
    }

    const body1 = formToObj(form1.current);
    const body2 = formToObj(form2.current);

    const mNacimiento =
      body1.FECHA_NACIMIENTO.toString().match(/^(\d+)-(\d+)-(\d+)$/);
    if (mNacimiento) {
      body1.FECHA_NACIMIENTO = `${mNacimiento[3]}/${mNacimiento[2]}/${mNacimiento[1]}`;
    }

    const mNacimiento2 =
      body2.FECHA_NACIMIENTO.toString().match(/^(\d+)-(\d+)-(\d+)$/);
    if (mNacimiento2) {
      body2.FECHA_NACIMIENTO = `${mNacimiento2[3]}/${mNacimiento2[2]}/${mNacimiento2[1]}`;
    }

    const pcRequest: IResponseSolicitud = {
      SOLICITUD: {
        ...objSolicitud.SOLICITUD,
        FAMILIARES: [
          {
            FAMILIAR: {
              ...objSolicitud.SOLICITUD.FAMILIARES[0]?.FAMILIAR,
              ...body1,
            },
          },
          {
            FAMILIAR: {
              ...objSolicitud.SOLICITUD.FAMILIARES[1]?.FAMILIAR,
              ...body2,
            },
          },
        ],
      },
    };
    setIsFetching(true);
    Api("consumir")
      .post({
        servicio: "altaSolicitud",
        param: {
          pn_pagina: 4,
          pc_request: OBJtoXML(pcRequest),
        },
      })
      .then((res) => {
        setIsFetching(false);
        if (res.mensaje.tipo === "M") {
          dispatch(setSolicitud(pcRequest));
          dispatch(nextSteep(objSolicitud.SOLICITUD.VIAJE_PARA));
          return;
        }
        setApiMessage(res.mensaje.descripcion);
      });
  }

  return (
    <div className="container mb-5">
      <form ref={form1} className="row">
        <div className="col-md-3 col-12">
          <span>Parentezco:</span>
          <select name="PARENTESCO" className="form-select">
            {campos.parentescos.map((e) => (
              <option value={e.codigo} key={e.codigo}>
                {e.dato}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-6 col-12"></div>
        <div className="col-md-6 col-12 my-2">
          <span>Apellido:</span>
          <input name="APELLIDO" className="form-control" type="text" />
        </div>
        <div className="col-md-6 col-12 my-2">
          <span>Nombre:</span>
          <input name="NOMBRE" className="form-control" type="text" />
        </div>
        <div className="col-md-4 col-12 my-2">
          <span>Tipo de documento:</span>
          <select name="TIPO_DOCUMENTO" className="form-select">
            {campos.documentos.map((campo) => (
              <option key={campo.codigo} value={campo.codigo}>
                {campo.dato}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-4 col-12 my-2">
          <span>Número de documento:</span>
          <input name="NRO_DOCUMENTO" className="form-control" type="number" />
        </div>
        <div className="col-md-4 col-12 my-2">
          <span>Fecha de Nacimiento:</span>
          <input name="FECHA_NACIMIENTO" className="form-control" type="date" />
        </div>
        <div className="col-12 my-2">
          <span>Sexo:</span>
          <select name="SEXO" className="form-select">
            {campos.sexos.map((campo) => (
              <option key={campo.codigo} value={campo.codigo}>
                {campo.dato}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-4 col-12">
          <span>Teléfono Fijo - DDN:</span>
          <input name="TELEFONO" className="form-control" type="tel" />
        </div>
        <div className="col-md-4 col-12">
          <span>Teléfono Móvil - DDN:</span>
          <input name="CELULAR" className="form-control" type="tel" />
        </div>
        <div className="col-md-4 col-12">
          <span>Mail:</span>
          <input name="EMAIL" className="form-control" type="email" />
        </div>
      </form>
      <hr className="my-5" />
      <form ref={form2} className="row">
        <div className="col-md-3 col-12">
          <span>Parentezco:</span>
          <select name="PARENTESCO" className="form-select">
            {campos.parentescos.map((e) => (
              <option value={e.codigo} key={e.codigo}>
                {e.dato}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-6 col-12"></div>
        <div className="col-md-6 col-12 my-2">
          <span>Apellido:</span>
          <input name="APELLIDO" className="form-control" type="text" />
        </div>
        <div className="col-md-6 col-12 my-2">
          <span>Nombre:</span>
          <input name="NOMBRE" className="form-control" type="text" />
        </div>
        <div className="col-md-4 col-12 my-2">
          <span>Tipo de documento:</span>
          <select name="TIPO_DOCUMENTO" className="form-select">
            {campos.documentos.map((campo) => (
              <option key={campo.codigo} value={campo.codigo}>
                {campo.dato}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-4 col-12 my-2">
          <span>Número de documento:</span>
          <input name="NRO_DOCUMENTO" className="form-control" type="number" />
        </div>
        <div className="col-md-4 col-12 my-2">
          <span>Fecha de Nacimiento:</span>
          <input name="FECHA_NACIMIENTO" className="form-control" type="date" />
        </div>
        <div className="col-12 my-2">
          <span>Sexo:</span>
          <select name="SEXO" className="form-select">
            {campos.sexos.map((campo) => (
              <option key={campo.codigo} value={campo.codigo}>
                {campo.dato}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-4 col-12">
          <span>Teléfono Fijo - DDN:</span>
          <input name="TELEFONO" className="form-control" type="tel" />
        </div>
        <div className="col-md-4 col-12">
          <span>Teléfono Móvil - DDN:</span>
          <input name="CELULAR" className="form-control" type="tel" />
        </div>
        <div className="col-md-4 col-12">
          <span>Mail:</span>
          <input name="EMAIL" className="form-control" type="email" />
        </div>
      </form>
      <div className="text-center mt-4">
        <Button secondary title="Volver" onPress={handleClickBack} />
        <Button
          title="Continuar"
          onLoad={isFetching}
          onPress={handleClickValidar}
        />
      </div>
      {apiMessage ? (
        <div dangerouslySetInnerHTML={{ __html: apiMessage }}></div>
      ) : (
        <></>
      )}
    </div>
  );
}
