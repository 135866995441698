import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/reducers";
import { setSolicitud } from "../../store/actions/objSolicitudActions";
import { nextSteep } from "../../store/actions/solicitudActions";
import Api from "../../utils/api";
import OBJtoXML from "../../utils/objToXML";
import IResponseSolicitud from "../../interfaces/IResponseSolicitud";
import Button from "../Button";
import formToObj from "../../utils/formToObj";

export default function DatosDelViaje() {
  const form = useRef(null);
  const [isFetching, setIsFetching] = useState(false);
  const [apiMessage, setApiMessage] = useState("");

  const dispatch = useDispatch();
  const campos = useSelector((state: RootState) => state.campoSolicitud);
  const objSolicitud = useSelector((state: RootState) => state.ObjSolicitud);

  function handleClickValidar() {
    setApiMessage("");
    if (!form.current) {
      return;
    }
    const body = formToObj(form.current);
    const pcRequest: IResponseSolicitud = {
      SOLICITUD: {
        ...objSolicitud.SOLICITUD,
        ...body,
      },
    };
    setIsFetching(true);
    Api("consumir")
      .post({
        servicio: "altaSolicitud",
        param: {
          pn_pagina: 2,
          pc_request: OBJtoXML(pcRequest),
        },
      })
      .then((res) => {
        setIsFetching(false);
        if (res.mensaje.tipo === "M") {
          dispatch(setSolicitud(pcRequest));
          dispatch(nextSteep(objSolicitud.SOLICITUD.VIAJE_PARA));
          return;
        }
        setApiMessage(res.mensaje.descripcion);
      });
  }

  return (
    <div className="container mb-5">
      <form ref={form} className="row">
        <div className="col-md-6 col-12 my-2">
          <span>Solicitud para:</span>
          <select className="form-select" name="VIAJE_PARA" required>
            <option value=""></option>
            {campos.solicitudPara.map((option) => (
              <option key={option.codigo} value={option.codigo}>
                {option.dato}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-8 col-12 my-2">
          <span>destino:</span>
          <select
            className="form-select"
            value={campos.colegios[0]?.destino}
            name="DESTINO"
            disabled
          >
            {campos.destinos.map((option) => (
              <option key={option.codigo} value={option.codigo}>
                {option.dato}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-4 col-12 my-2">
          <span>año de viaje:</span>
          <select
            className="form-select"
            name="VIAJE_ANIO"
            disabled
            required
            value={campos.colegios[0]?.tipoViaje}
          >
            {campos.tiposViajes.map((option) => (
              <option key={option.codigo} value={option.codigo}>
                {option.dato}
              </option>
            ))}
          </select>
        </div>
        <div className="col-12 my-2">
          <span>colegio:</span>
          <input
            className="form-control"
            type="text"
            name="ESTABLECIMIENTO"
            placeholder="colegio"
            value={campos.colegios[0]?.colegio}
            disabled
            required
          />
        </div>
        <div className="col-md-4 col-12 my-2">
          <span>año:</span>
          <input
            className="form-control"
            type="text"
            name="ANIO"
            placeholder="anio"
            value={campos.colegios[0]?.anio}
            disabled
            required
          />
        </div>
        <div className="col-md-4 col-12 my-2">
          <span>division:</span>
          <input
            className="form-control"
            type="text"
            name="DIVISION"
            placeholder="division"
            value={campos.colegios[0]?.division}
            disabled
            required
          />
        </div>
        <div className="col-md-4 col-12 my-2">
          <span>turno:</span>
          <select
            className="form-select"
            name="TURNO"
            defaultValue={campos.colegios[0]?.turno}
            required
            disabled
          >
            {campos.turnos.map((option) => (
              <option key={option.codigo} value={option.codigo}>
                {option.dato}
              </option>
            ))}
          </select>
        </div>
        <div className="text-center mt-4">
          <Button
            title="Continuar"
            onPress={handleClickValidar}
            onLoad={isFetching}
          />
        </div>
      </form>
      {apiMessage ? (
        <div dangerouslySetInnerHTML={{ __html: apiMessage }}></div>
      ) : (
        <></>
      )}
    </div>
  );
}
