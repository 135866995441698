import { useRef, useState } from "react";
import Api from "../../utils/api";
import ICampoSolicitud from "../../interfaces/ICampoSolicitud";
import { useDispatch, useSelector } from "react-redux";
import {
  setCamposSolicitud,
  nextSteep,
} from "../../store/actions/solicitudActions";
import { setSolicitud } from "../../store/actions/objSolicitudActions";
import { RootState } from "../../store/reducers";

import Button from "../Button";

export default function CodigoGrupo() {
  const form = useRef<HTMLFormElement>(null);
  const [token, setToken] = useState("");
  const [apiMessage, setApiMessage] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const dispatch = useDispatch();
  const objSolicitud = useSelector((state: RootState) => state.ObjSolicitud);
  function handleClickValidar() {
    setApiMessage("");
    if (!form.current?.checkValidity) {
      return;
    }
    setIsFetching(true);
    Api("consumir")
      .post({
        servicio: "validarClaveGrupo",
        param: {
          ptoken: token,
        },
      })
      .then((res) => {
        setIsFetching(false);
        if (res.mensaje.tipo === "E") {
          setApiMessage(res.mensaje.descripcion);
          return;
        }

        const campoSolicitud: ICampoSolicitud = {
          colegios: res.PREF_COLEGIO.map((e: any) => ({
            anio: e.ANIO,
            colegio: e.COLEGIO,
            destino: e.CO_DESTINO,
            division: e.DIVISION,
            turno: e.TURNO,
            tipoViaje: e.TVIAJE,
          })),
          destinos: res.PREF_DESTINOS.map((e: any) => ({
            codigo: e.CODIGO,
            dato: e.DATO,
          })),
          documentos: res.PREF_DOCUMENTOS.map((e: any) => ({
            codigo: e.CODIGO,
            dato: e.DATO,
          })),
          parentescos: res.PREF_PARENTESCOS.map((e: any) => ({
            codigo: e.CODIGO,
            dato: e.DATO,
          })),
          planes: res.PREF_PLANES.map((e: any) => ({
            codigo: e.CODIGO,
            dato: e.DATO,
          })),
          pqnoselegiste: res.PREF_PQNOSELEGISTE.map((e: any) => ({
            codigo: e.CODIGO,
            dato: e.DATO,
          })),
          provincias: res.PREF_PROVINCIAS.map((e: any) => ({
            codigo: e.CODIGO,
            dato: e.DATO,
          })),
          sexos: res.PREF_SEXOS.map((e: any) => ({
            codigo: e.CODIGO,
            dato: e.DATO,
          })),
          solicitudPara: res.PREF_SOL_PARA.map((e: any) => ({
            codigo: e.CODIGO,
            dato: e.DATO,
          })),
          talles: res.PREF_TALLES.map((e: any) => ({
            codigo: e.CODIGO,
            dato: e.DATO,
          })),
          turnos: res.PREF_TURNOS.map((e: any) => ({
            codigo: e.CODIGO,
            dato: e.DATO,
          })),
          tiposViajes: res.PREF_TVIAJES.map((e: any) => ({
            codigo: e.CODIGO,
            dato: e.DATO,
          })),
          tyc: res.PREF_TYC.map((e: any) => ({
            destino: e.CO_DESTINO,
            tipoViaje: e.TVIAJE,
            tyc: e.TYC,
          })),
          steep: 0,
        };

        dispatch(setCamposSolicitud(campoSolicitud));
        dispatch(
          setSolicitud({
            SOLICITUD: { ...objSolicitud.SOLICITUD, CLAVE_GRUPO: token },
          })
        );
        dispatch(nextSteep(objSolicitud.SOLICITUD.VIAJE_PARA));
      });
  }

  function handleChangeToken(e: React.ChangeEvent<HTMLInputElement>) {
    setToken(e.target.value);
  }

  return (
    <div className="container">
      <form ref={form} style={{ margin: "1em" }}>
        <span>Codigo de grupo:</span>
        <input
          className="form-control my-2"
          type="text"
          name="codigo"
          placeholder="A30DB21FR"
          onChange={handleChangeToken}
          required
        />
        <div className="text-center mt-4">
          <Button
            onPress={handleClickValidar}
            title="Validar"
            onLoad={isFetching}
          />
        </div>
      </form>
      {apiMessage ? <p>{apiMessage}</p> : <></>}
    </div>
  );
}
