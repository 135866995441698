import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setRoute } from "../../store/actions/routeActions";
import Api from "../../utils/api";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import DownloadFile from "../../utils/downloadFile";

interface ICuota {
  CLAVE_EMISION: string;
  CO_PASAJERO: string;
  NU_TALON: string;
}

interface IDato {
  D_GRUPO: string;
  D_ID: string;
  D_TITULO: string;
  D_VALOR: string;
  N_ID: string;
  N_ORDEN: string;
}

export default function ActualizarCuota() {
  const { id = "-1" } = useParams();
  const [, setOnLoad] = useState(false);
  const [datos, setDatos] = useState<Array<IDato>>([]);
  const [actualizando, setActualizando] = useState(false);
  const [cuotas, setCuotas] = useState<Array<ICuota>>([]);
  const [print, setPrint] = useState(false);
  const [messageModal, setMessageModal] = useState("");
  const [messageApi, setMessageApi] = useState("");
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setRoute({
        section: "pasajero",
        sub: "cuotas-y-pago",
        cp: id,
      })
    );
  }, [dispatch, id]);

  useEffect(() => {
    setOnLoad(true);
    Api("consumir")
      .post({
        servicio: "talonActMostrar",
        param: {
          pco_pasajero: id,
        },
      })
      .then((res) => {
        if (res.PM_ERROR !== "M") {
          setMessageApi(res.PD_ERROR);
          return;
        }
        setDatos(res.PREF_DATOS);
        setOnLoad(false);
      });
  }, [id]);

  const actualizarCuota = () => {
    setActualizando(true);
    Api("consumir")
      .post({
        servicio: "talonActAplicar",
        param: {
          pco_pasajero: id,
        },
      })
      .then((res) => {
        if (res.PM_ERROR !== "M") {
          setMessageApi(res.PD_ERROR);
          return;
        }
        setCuotas(res.PREF_CUOTAS);
        setMessageModal(res.PD_ERROR);
        setShowModal(true);
      })
      .finally(() => {
        setActualizando(false);
      });
  };

  const handleClickPrint = () => {
    setPrint(true);
    Api("birt")
      .post(
        {
          param: {
            prm_co_pasajero: id,
            prm_nu_talon: cuotas[0].NU_TALON,
          },
          reporte: "SV_cupon_SI.rptdesign",
        },
        "text/plain"
      )
      .then((res: string) => {
        DownloadFile(res, `${id} - Talon ${cuotas[0].NU_TALON}`);
      })
      .finally(() => {
        setPrint(false);
      });
  };

  return (
    <div className="container-data pt-5">
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr className="th-platform">
              <th colSpan={2}>Información de la cuota vencida</th>
            </tr>
          </thead>
          <tbody>
            {datos.map((dato: IDato) => (
              <tr key={dato.N_ORDEN}>
                <td>{dato.D_TITULO}</td>
                <td>{dato.D_VALOR}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="text-center">
          <Button
            onLoad={actualizando}
            title="Actualizar cuota"
            onPress={() => actualizarCuota()}
          />
        </div>
      </div>
      <p className="text-center text-danger">{messageApi}</p>
      <Modal
        title="Cuota actualizada con éxito"
        show={showModal}
        onClose={() => setShowModal(false)}
      >
        <div className="text-center">
          <h5 dangerouslySetInnerHTML={{ __html: messageModal }}></h5>
          <Button
            title="Imprimir"
            onLoad={print}
            onPress={() => handleClickPrint()}
          />
        </div>
      </Modal>
    </div>
  );
}
