import React, { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/reducers";
import { setSolicitud } from "../../store/actions/objSolicitudActions";
import { nextSteep, backSteep } from "../../store/actions/solicitudActions";
import Api from "../../utils/api";
import OBJtoXML from "../../utils/objToXML";
import IResponseSolicitud from "../../interfaces/IResponseSolicitud";
import Button from "../Button";
import formToObj from "../../utils/formToObj";

export default function DatosDelPasajero() {
  const form = useRef(null);
  const [apiMessage, setApiMessage] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  const objSolicitud = useSelector((state: RootState) => state.ObjSolicitud);
  const campos = useSelector((state: RootState) => state.campoSolicitud);

  const dispatch = useDispatch();

  function handleClickBack() {
    dispatch(backSteep(objSolicitud.SOLICITUD.VIAJE_PARA));
  }

  function handleClickValidar() {
    setApiMessage("");

    if (!form.current) {
      return;
    }

    const body = formToObj(form.current);

    const mNacimiento =
      body.FECHA_NACIMIENTO.toString().match(/^(\d+)-(\d+)-(\d+)$/);
    if (mNacimiento) {
      body.FECHA_NACIMIENTO = `${mNacimiento[3]}/${mNacimiento[2]}/${mNacimiento[1]}`;
    }

    const mVencimiento =
      body.F_VEN_DOCUMENTO.toString().match(/^(\d+)-(\d+)-(\d+)$/);
    if (mVencimiento) {
      body.F_VEN_DOCUMENTO = `${mVencimiento[3]}/${mVencimiento[2]}/${mVencimiento[1]}`;
    }

    const pcRequest: IResponseSolicitud = {
      SOLICITUD: {
        ...objSolicitud.SOLICITUD,
        ...body,
      },
    };
    setIsFetching(true);
    Api("consumir")
      .post({
        servicio: "altaSolicitud",
        param: {
          pn_pagina: 3,
          pc_request: OBJtoXML(pcRequest),
        },
      })
      .then((res) => {
        setIsFetching(false);
        if (res.mensaje.tipo === "M") {
          dispatch(setSolicitud(pcRequest));
          dispatch(nextSteep(objSolicitud.SOLICITUD.VIAJE_PARA));
          return;
        }
        setApiMessage(res.mensaje.descripcion);
      });
  }

  return (
    <form ref={form} className="container mb-5">
      <div className="row">
        <div className="col-md-6 col-12 my-2">
          <span>Apellido:</span>
          <input className="form-control" name="APELLIDO" type="text" />
        </div>
        <div className="col-md-6 col-12 my-2">
          <span>Nombre:</span>
          <input name="NOMBRE" className="form-control" type="text" />
        </div>
        <div className="col-md-4 col-12 my-2">
          <span>Tipo de documento:</span>
          <select name="TIPO_DOCUMENTO" className="form-select">
            {campos.documentos.map((campo) => (
              <option key={campo.codigo} value={campo.codigo}>
                {campo.dato}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-4 col-12 my-2">
          <span>Número de documento:</span>
          <input name="NRO_DOCUMENTO" className="form-control" type="number" />
        </div>
        <div className="col-md-4 col-12 my-2">
          <span>Vencimiento del DNI:</span>
          <input name="F_VEN_DOCUMENTO" className="form-control" type="date" />
        </div>
        <div className="col-md-4 col-12 my-2">
          <span>Fecha de Nacimiento:</span>
          <input name="FECHA_NACIMIENTO" className="form-control" type="date" />
        </div>
        <div className="col-md-4 col-12 my-2">
          <span>Nacionalidad:</span>
          <input name="C_NACIONALIDAD" className="form-control" type="text" />
        </div>
        <div className="col-md-4 col-12 my-2"></div>
        <div className="col-md-4 col-12 my-2">
          <span>Sexo:</span>
          <select name="SEXO" className="form-select">
            {campos.sexos.map((campo) => (
              <option key={campo.codigo} value={campo.codigo}>
                {campo.dato}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-4 col-12 my-2">
          <span>Remera:</span>
          <select name="REMERA" className="form-select">
            {campos.talles.map((campo) => (
              <option key={campo.codigo} value={campo.codigo}>
                {campo.dato}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-4 col-12 my-2">
          <span>Pantalon:</span>
          <select name="PANTALON" className="form-select">
            {campos.talles.map((campo) => (
              <option key={campo.codigo} value={campo.codigo}>
                {campo.dato}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-4 col-12 my-2">
          <span>Campera:</span>
          <select name="CAMPERA" className="form-select">
            {campos.talles.map((campo) => (
              <option key={campo.codigo} value={campo.codigo}>
                {campo.dato}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-4 col-12 my-2">
          <span>Calzado:</span>
          <input name="CALZADO" className="form-control" type="number" />
        </div>
        <div className="col-md-4 col-12 my-2"></div>
        <div className="col-md-4 col-12 my-2">
          <span>Provincia:</span>
          <select name="PROVINCIA" className="form-select">
            {campos.provincias.map((campo) => (
              <option key={campo.codigo} value={campo.codigo}>
                {campo.dato}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-4 col-12 my-2">
          <span>Localidad:</span>
          <input name="LOCALIDAD" className="form-control" type="text" />
        </div>
        <div className="col-md-4 col-12 my-2">
          <span>Codigo Postal:</span>
          <input name="CODIGO_POSTAL" className="form-control" type="number" />
        </div>
        <div className="col-md-4 col-12 my-2">
          <span>Calle:</span>
          <input name="CALLE" className="form-control" type="text" />
        </div>
        <div className="col-md-4 col-12 my-2">
          <span>Altura:</span>
          <input name="ALTURA" className="form-control" type="text" />
        </div>
        <div className="col-md-2 col-12">
          <span>Piso:</span>
          <input name="PISO" className="form-control" type="text" />
        </div>
        <div className="col-md-2 col-12">
          <span>Depto:</span>
          <input name="DPTO" className="form-control" type="text" />
        </div>
        <div className="col-md-4 col-12">
          <span>Teléfono Fijo - DDN:</span>
          <input name="TELEFONO" className="form-control" type="tel" />
        </div>
        <div className="col-md-4 col-12">
          <span>Teléfono Móvil - DDN:</span>
          <input name="CELULAR" className="form-control" type="tel" />
        </div>
      </div>
      <div className="text-center mt-4">
        <Button secondary title="Volver" onPress={handleClickBack} />
        <Button
          title="Continuar"
          onLoad={isFetching}
          onPress={handleClickValidar}
        />
      </div>
      {apiMessage ? (
        <div dangerouslySetInnerHTML={{ __html: apiMessage }}></div>
      ) : (
        <></>
      )}
    </form>
  );
}
