import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setRoute } from "../../store/actions/routeActions";
import Button from "../../components/Button";
import Api from "../../utils/api";
import { SkeletonAlert, SkeletonTable } from "../../components/Skeleton";
import { RootState } from "../../store/reducers";
import IPrefPasajeros from "../../interfaces/IPrefPasajeros";

type Concurso = {
  C_RIFA: string;
  D_RIFA: string;
};

type IResponseTipo = {
  PCUR_TIPO_RIFA: Array<Concurso>;
};

type ConcursoCreado = {
  DOCUMENTO: string;
  D_APELLIDO: string;
  D_EMAIL: string;
  D_NOMBRE: string;
  D_TELEFONO: string;
  N_DOCUMENTO: string;
};

type IResponseLista = {
  PCUR_RIFA: Array<ConcursoCreado>;
  PM_BOTON_ALTA: string;
};

export default function Concursos() {
  const [concursos, setConcursos] = useState<Array<Concurso> | null>(null);
  const [concursosLista, setConcursosLista] =
    useState<Array<ConcursoCreado> | null>(null);
  const [concursoSelected, setConcrusoSelected] = useState<string>("");
  const [showBtn, setShowBtn] = useState(false);
  const [nombre, setNombre] = useState<string>("");

  const pasajeros = useSelector((e: RootState) => e.pasajeros);

  const { id = "-1" } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setRoute({
        section: "pasajero",
        sub: "concursos",
        cp: id,
      })
    );
    setNombre(
      pasajeros.pasajeros.find((p: IPrefPasajeros) => p.CO_PASAJERO === id)
        ?.D_APEYNOM || ""
    );
  }, [dispatch, id, pasajeros.pasajeros]);

  useEffect(() => {
    Api("consumir")
      .post({
        servicio: "rifaListaTipo",
        param: {
          pco_pasajero: id,
        },
      })
      .then((res: IResponseTipo) => {
        setConcursos(res.PCUR_TIPO_RIFA);
        if (res.PCUR_TIPO_RIFA.length) {
          setConcrusoSelected(res.PCUR_TIPO_RIFA[0].C_RIFA ?? "");
          return;
        }
        setConcrusoSelected("");
        setConcursosLista([]);
      });
  }, [id]);

  useEffect(() => {
    if (!concursoSelected) return;
    setConcursosLista(null);
    setShowBtn(false);
    Api("consumir")
      .post({
        servicio: "rifaLista",
        param: {
          pco_pasajero: id,
          pc_rifa: concursoSelected,
        },
      })
      .then((res: IResponseLista) => {
        setConcursosLista(res.PCUR_RIFA);
        setShowBtn(res.PM_BOTON_ALTA === "S");
      });
  }, [id, concursoSelected]);

  const handleRegisterCompetition = () => {
    navigate(
      `/pasajero/${id}/${nombre
        .replace("*", "")
        .replaceAll(" ", "-")}/concursos/${concursoSelected}`
    );
  };

  return (
    <div className="container-data mt-4">
      {concursos && !concursos.length && (
        <h5>No se encontraron concursos disponibles</h5>
      )}
      {concursos && (
        <form
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr auto",
            gap: "1em",
          }}
        >
          <span>Concurso</span>
          <select
            className="form-select"
            onChange={(e) => setConcrusoSelected(e.target.value)}
            defaultValue={concursoSelected}
          >
            {concursos.map((e) => (
              <option key={e.C_RIFA} value={e.C_RIFA}>
                {e.D_RIFA}
              </option>
            ))}
          </select>
          {showBtn && (
            <Button
              onPress={handleRegisterCompetition}
              title="Registrar un Concurso"
            />
          )}
        </form>
      )}
      {!concursos && <SkeletonAlert />}

      <div className="table-responsive mt-4">
        {!concursosLista && <SkeletonTable />}
        {concursosLista && (
          <table className="table table-striped">
            <thead>
              <tr className="th-platform">
                <th>Apellido</th>
                <th>Nombre</th>
                <th>Tipo Doc.</th>
                <th>Nro. Documento</th>
                <th>Email</th>
                <th>Teléfono</th>
              </tr>
            </thead>
            <tbody>
              {concursosLista.map((e, k) => (
                <tr key={k}>
                  <td>{e.D_APELLIDO}</td>
                  <td>{e.D_NOMBRE}</td>
                  <td>{e.DOCUMENTO}</td>
                  <td>{e.N_DOCUMENTO}</td>
                  <td>{e.D_EMAIL}</td>
                  <td>{e.D_TELEFONO}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}
